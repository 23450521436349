import React from "react";

import Register from "../../images/svg/register.svg";
import Connect from "../../images/svg/connect.svg";
import Repeat from "../../images/svg/repeat.svg";

interface HowItWorksProps {}

const HowItWorks: React.FC<HowItWorksProps> = props => {
  return (
    <div className="bg-gray-50 px-4 sm:px-6 py-16">
      <div className="max-w-7xl mx-auto">
        <div className="bg-lightblue-500 rounded-lg shadow-xl overflow-hidden text-center">
          <div className="pt-10 pb-12 mx-4 md:px-6 sm:pt-16 sm:px-16 lg:py-16 xl:py-20 xl:px-20">
            <h2 className="text-4xl font-extrabold text-white md:text-5xl">
              <span className="block">How it works</span>
            </h2>
            <p className="mx-auto mt-4 text-xl text-center text-white">
              Getting started with more accessible and convenient healthcare is
              easy.
            </p>
            <div className="mx-auto mt-8 lg:grid lg:grid-cols-3 lg:gap-5 space-y-6 lg:space-y-0 max-w-xl lg:max-w-full">
              <div className="mx-auto bg-white rounded-lg p-4 shadow-lg relative">
                <div className="bg-gray-900 absolute h-9 w-9 rounded-full">
                  <p className="text-white flex justify-center items-center h-full font-normal text-lg">
                    1
                  </p>
                </div>
                <Register className="h-16 mx-auto" />
                <h4 className="text-xl font-medium leading-6 text-gray-900 py-2">
                  Register
                </h4>
                <p className="mt-2 text-base text-gray-500 text-left">
                  Complete a quick registration form with your name, email and
                  phone number. Don't worry, your information is secure.
                </p>
              </div>
              <div className="mx-auto bg-white rounded-lg p-4 shadow-lg relative">
                <div className="bg-gray-900 absolute h-9 w-9 rounded-full">
                  <p className="text-white flex justify-center items-center h-full font-normal text-lg">
                    2
                  </p>
                </div>
                <Connect className="h-16 mx-auto" />
                <h4 className="text-xl font-medium leading-6 text-gray-900 my-2">
                  Connect
                </h4>
                <p className="mt-2 text-base text-gray-500 text-left">
                  We will give you a call or send a link for a virtual meet and
                  greet. We'll discuss and come up with a plan for any immediate
                  concerns.
                </p>
              </div>
              <div className="mx-auto bg-white rounded-lg p-4 shadow-lg relative">
                <div className="bg-gray-900 absolute h-9 w-9 rounded-full">
                  <p className="text-white flex justify-center items-center h-full font-normal text-lg">
                    3
                  </p>
                </div>
                <Repeat className="h-16 mx-auto" />
                <h4 className="text-xl font-medium leading-6 text-gray-900 py-2">
                  Follow-up
                </h4>
                <p className="mt-2 text-base text-gray-500 text-left">
                  Contact us through email, phone, SMS or our app (coming soon)
                  to get in touch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
