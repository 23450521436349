// This Nav is only for the home page. The difference is that it scrolls to a particular element on the page.

import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

// Image Assets
import LogoLongDark from "../../images/svg/logo-long-dark.svg";
import Hamburger from "../../images/svg/hamburger.svg";
import CloseIcon from "../../images/svg/x.svg";

interface NavProps {}

const Nav: React.FC<NavProps> = props => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        className={`fixed z-30 w-full transition duration-500 ${
          offset > 20 && "bg-white shadow-lg"
        } px-4 sm:px-6`}
      >
        <div className="mx-auto max-w-7xl ">
          <div className="flex items-center justify-between py-6 space-x-10 ">
            <Link to="/">
              <LogoLongDark alt="Logo" />
            </Link>
            {/* Normal Menu */}
            <div className="flex-1 hidden lg:flex lg:justify-between lg:items-center">
              <nav className="flex space-x-10">
                <Link
                  to="#"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  How it works
                </Link>
                <Link
                  to="#"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Services
                </Link>
                <Link
                  to="#"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  About
                </Link>
                <Link
                  to="#"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Resources
                </Link>
              </nav>
              <div className="flex">
                <Link
                  to="#"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-lightblue-500 hover:bg-lightblue-600"
                >
                  Start now
                </Link>
              </div>
            </div>
            <div className="lg:hidden">
              <button
                className="inline-flex items-center justify-center p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lightblue-500"
                onClick={() => setMobileMenuOpen(true)}
              >
                <Hamburger className="w-6 h-6" />
              </button>
            </div>
          </div>
          {/* Mobile menu */}
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="p-4 fixed z-40 w-full top-0 transition origin-top-right transform lg:hidden">
          <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <Link to="/" onClick={() => setMobileMenuOpen(false)}>
                  <LogoLongDark alt="Logo" />
                </Link>
                <button
                  className="inline-flex items-center justify-center p-2 text-gray-400 bg-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lightblue-500"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <CloseIcon className="w-6 h-6" />
                </button>
              </div>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
              <h1>klasdjfas</h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
