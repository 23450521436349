import React from "react";

// Image assets
import EverydayCare from "../../images/svg/everyday-care.svg";
import Covid from "../../images/svg/covid.svg";
import Family from "../../images/svg/family.svg";
import MentalHealth from "../../images/svg/mental-health.svg";
import Sex from "../../images/svg/sex.svg";
import Chronic from "../../images/svg/chronic.svg";
import Heart from "../../images/svg/heart.svg";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

interface ServicesProps {}

const Services: React.FC<ServicesProps> = props => {
  return (
    <div className="py-16 overflow-hidden bg-gray-50 lg:py-24 px-4 sm:px-6">
      <div className="max-w-xl mx-auto lg:max-w-7xl">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-4xl font-extrabold tracking-tight text-center text-gray-900 md:text-5xl">
            A medical practice designed around you
          </h2>
          <p className="mx-auto mt-4 text-xl text-center text-gray-500">
            This is no ordinary doctor’s office. Rather than do things the
            typical way, we've created a primary care practice truly designed
            around our patients' needs.
          </p>
        </div>
        <div className="mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h3 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Our services
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              At Cloud Medical, we care for everything from common illnesses to
              chronic diseases and mental health concerns. Primary care isn’t
              just for when you’re sick — we’re also here to help you prevent
              illness and maintain your optimal health.
            </p>
            <dl className="mt-10 space-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-16 h-16 text-white rounded-md">
                    <EverydayCare />
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-xl font-medium leading-6 text-gray-900">
                    Everyday care
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Wake up with the flu or a questionable rash? We diagnose and
                    treat a wide spectrum of illnesses, so you get the care you
                    need without having to visit the ER or see specialists.
                  </dd>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-16 h-16 rounded-md">
                    <Covid />
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-xl font-medium leading-6 text-gray-900">
                    COVID-19
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Have questions about COVID-19? Our doctor's can advise on
                    next steps if you think you may have been infected. If
                    required, we will coordinte with hospitals and followup once
                    you are discharged.
                  </dd>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-16 h-16 rounded-md">
                    <MentalHealth />
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-xl font-medium leading-6 text-gray-900">
                    Mental health
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Your mental health is an important part of the full picture.
                    Whether you're experiencing depression, anxiety, insomnia,
                    or something more complex, our doctor's can diagnose,
                    prescribe and refer when necessary.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
          <div className="mt-10 lg:mt-0" aria-hidden="true">
            {/* <img
              className="mx-auto"
              src={Blob2}
              width="600px"
              height="600px"
              alt=""
            /> */}
            
            <StaticImage
              src="../images/home/blob-services-1.png"
              alt="TODO"
              placeholder="tracedSVG"
              layout="constrained"
            />
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <dl className="mt-10 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md">
                      <Chronic />
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-xl font-medium leading-6 text-gray-900">
                      Chronic conditions
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      From arthritis to zaspopathy, we’ll diagnose and help you
                      manage an enormous range of conditions, and when necessary
                      we’ll connect you to world-class specialists and
                      institutions to ensure you get the best possible care.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md">
                      <Family />
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-xl font-medium leading-6 text-gray-900">
                      Children and family
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      We’ve created a welcoming, nurturing environment for the
                      whole family and strive to help foster healthy habits in
                      our youngest patients.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md">
                      <Sex />
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-xl font-medium leading-6 text-gray-900">
                      Sexual health
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Talking to your provider about your sex life doesn’t have
                      to be uncomfortable. For anything from STI testing to
                      birth control and family planning to erectile dysfunction,
                      our friendly providers and comfortable, inclusive offices
                      are there to put you at ease.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md">
                      <Heart />
                    </div>
                  </div>
                  <div className="ml-4">
                    <dt className="text-xl font-medium leading-6 text-gray-900">
                      And much more
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      We offer much more than what is listed here including
                      smoking cessation, alcoholism, and pretty much any other
                      service regular doctor's office would provide.
                    </dd>
                    <Link
                      className="inline-block my-2 text-lg font-normal text-lightblue-500"
                      to="/"
                    >
                      See all services ›
                    </Link>
                  </div>
                </div>
              </dl>
            </div>
            <div className="mt-10 lg:mt-0 lg:col-start-1">
              {/* <img
                className="max-w-full mx-auto"
                src={Blob3}
                width="600px"
                height="600px"
                alt=""
              /> */}

              <StaticImage
                src="../images/home/blob-services-2.png"
                alt="TODO"
                placeholder="tracedSVG"
                layout="constrained"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
