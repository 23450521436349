import { Link } from 'gatsby';
import React, { useState } from "react";

import ChevronDown from "../../images/svg/chevron-down.svg";

interface IFaq {
  id: number;
  question: string;
  answer: string;
}

interface FaqProps {}

const Faq: React.FC<FaqProps> = props => {
  const [expandedId, setExpandedId] = useState(-1);

  const faqData: IFaq[] = [
    {
      id: 0,
      question: "First title",
      answer: "First answer",
    },
    {
      id: 1,
      question: "Second title",
      answer: "Second answer",
    },
    {
      id: 2,
      question: "Third title",
      answer: "Third answer",
    },
  ];

  return (
    <div className="bg-gray-50 px-4 sm:px-6">
      <div className="lg:max-w-7xl max-w-xl mx-auto py-16 lg:py-20">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-4xl font-extrabold tracking-tight text-center lg:text-left text-gray-900 md:text-5xl">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-xl text-gray-500 text-center lg:text-left">
              Have more questions? View all{" "}
              <Link
                to="#"
                className="font-medium text-lightblue-500 hover:text-lightblue-600"
              >
                FAQs
              </Link>{" "}
              or{" "}
              <Link
                to="#"
                className="font-medium text-lightblue-500 hover:text-lightblue-600"
              >
                contact us
              </Link>
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-8">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  How can this replace in-person care?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We replace the family aspect of in-person care. Need a prescription refill, or have a urinary tract infection? Get help. 
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Do I have to pay to use this service?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  I don't know, but the flag is a big plus. Lorem ipsum dolor
                  sit amet consectetur adipisicing elit. Quas cupiditate
                  laboriosam fugiat.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  What do you call someone with no body and no nose?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Nobody knows. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Quas cupiditate laboriosam fugiat.
                </dd>
              </div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Why do you never see elephants hiding in trees?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Because they're so good at it. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Quas cupiditate laboriosam
                  fugiat.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
