import React from "react";

import Quote from "../../images/svg/quote.svg";

interface ReviewsProps {}

const Reviews: React.FC<ReviewsProps> = props => {
  return (
    <div className="bg-gray-50 px-4 sm:px-6 py-16">
      <div className="max-w-7xl mx-auto">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-4xl font-extrabold tracking-tight text-center text-gray-900 md:text-5xl">
            Our patients love us
          </h2>
          <p className="mt-4 text-xl text-gray-500 text-center">
            These are just few of the comments we've recieved by some of our patients.
          </p>
        </div>
        <div className="mx-auto mt-8 lg:grid lg:grid-cols-3 lg:gap-5 space-y-6 lg:space-y-0 max-w-xl lg:max-w-full">
          <div className="mx-auto bg-lightblue-500 rounded-lg p-4 shadow-lg">
            <Quote className="h-8 w-8 text-white opacity-100" />
            <p className="mt-2 text-base text-white text-left">
              This service is excellent for me on the go. The responsiveness and
              the care of the staff makes it one of the best services available.
            </p>
          </div>
          <div className="mx-auto bg-lightblue-500 rounded-lg p-4 shadow-lg">
            <Quote className="h-8 w-8 text-white opacity-100" />
            <p className="mt-2 text-base text-white text-left">
              I just love having to be able to call and get help when I need it without having to change my schedule to go to the doctor. Plus, they have all my history so its super quick!
            </p>
          </div>
          <div className="mx-auto bg-lightblue-500 rounded-lg p-4 shadow-lg">
            <Quote className="h-8 w-8 text-white opacity-100" />
            <p className="mt-2 text-base text-white text-left">
              I transferred my complete family to this service and I am so happy with that decision. They help with everything I need and the convinience and speed are the most important aspect.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
