import React from "react";
import { StaticImage } from 'gatsby-plugin-image';

interface Cta {}

const Cta: React.FC<Cta> = props => {
  return (
    <div className="px-4 sm:px-6 py-16 bg-lightblue-50">
      <div className="mx-auto max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
          <div className="order-last lg:order-first pt-8 lg:pt-0 sm:max-w-lg sm:mx-auto lg:m-0 lg:max-w-none lg:mx-0 lg:flex lg:items-center">
            {/* <img
              className="w-96 mx-auto"
              src="https://www.zemedy.com/assets/photos/photo_0005.png"
              alt=""
            /> */}
            <StaticImage
              src="../../images/home/blob-cta.png"
              alt="TODO"
              placeholder="tracedSVG"
              layout="constrained"
              className=""
            />
          </div>
          <div className="pt-0 lg:pt-8 sm:text-center md:max-w-2xl md:mx-auto lg:text-left">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
              Get started with exceptional primary care
            </h2>
            <form action="#" className="mt-8 sm:max-w-lg sm:w-full sm:flex">
              <div className="min-w-0 flex-1">
                <label htmlFor="hero_email" className="sr-only">
                  Email address
                </label>
                <input
                  id="hero_email"
                  type="email"
                  className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-lightblue-500 focus:ring-lightblue-500"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  className="block w-full rounded-md border border-transparent px-5 py-3 bg-lightblue-500 text-base font-medium text-white shadow hover:bg-lightblue-600 focus:outline-none focus:ring-2 focus:ring-lightblue-500 focus:ring-offset-2 sm:px-10"
                >
                  Get started
                </button>
              </div>
            </form>

            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 flex items-center">
              {/* <Ontario className="h-5 text-gray-500" /> */}
              <p className="text-lg text-gray-500 font-normal mx-5">
                Covered by OHIP
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
