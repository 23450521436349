import React from "react";

import X from "../../images/svg/x.svg";
import Check from "../../images/svg/check.svg";
import Cloud from "../../images/svg/cloud.svg"
import { Formik } from "formik";

interface ComparisonData {
  comparisonText: string;
  cloudMedical: "yes" | "no" | "unlikely";
  otherDoctors: "yes" | "no" | "unlikely";
}

interface ComparisonProps {}

const Comparison: React.FC<ComparisonProps> = props => {
  const comparisonData: ComparisonData[] = [
    {
      comparisonText: "Same or next-day appointments",
      cloudMedical: "yes",
      otherDoctors: "no",
    },
    {
      comparisonText: "Appointments start on time",
      cloudMedical: "yes",
      otherDoctors: "unlikely",
    },
    {
      comparisonText: "Longer, non-rushed appointments",
      cloudMedical: "yes",
      otherDoctors: "no",
    },
    {
      comparisonText: "Increased follow-ups",
      cloudMedical: "yes",
      otherDoctors: "unlikely",
    },
    {
      comparisonText: "Something else",
      cloudMedical: "yes",
      otherDoctors: "no",
    },
  ];

  const getComparisonIcon = (comparator: string) => {
    if (comparator === "yes") {
      return (
        <div>
          <Check className="w-6 h-6 mx-auto text-green-500" />
          <span className="sr-only">Included in Essential</span>
        </div>
      );
    } else if (comparator === "no") {
      return (
        <div>
          <X className="w-6 h-6 mx-auto text-red-500" />
          <span className="sr-only">Included in Essential</span>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <span className="mx-auto font-normal text-red-500 text-small md:text-base">
            Unlikely
          </span>
        </div>
      );
    }
  };

  return (
    <div className="bg-gray-900">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-extrabold tracking-tight text-center text-white md:text-5xl">
            Going above and beyond your typical doctor's office
          </h2>
        </div>

        <table className="items-center w-full max-w-4xl mx-auto my-4">
          <thead>
            <tr>
              <th
                className="w-1/3 py-2 text-sm font-medium text-gray-900"
                scope="col"
              >
                <span className="sr-only"></span>
                <span></span>
              </th>

              <th
                className="w-1/3 py-2 text-sm font-medium leading-6 text-white md:text-lg"
                scope="col"
              >
                <div className="flex items-center justify-center">
                  <Cloud className="h-5 mr-2 hidden sm:block" />
                  Cloud Medical
                </div>

              </th>

              <th
                className="w-1/3 py-2 text-sm font-medium leading-6 text-white md:text-lg"
                scope="col"
              >
                Typical doctor's offices
              </th>
            </tr>
          </thead>
          <tbody className="border-gray-600 divide-y divide-gray-600">
            {comparisonData.map((data: ComparisonData) => (
              <tr key={data.comparisonText}>
                <th
                  className="py-5 text-sm font-normal text-left text-white md:pr-5 md:text-base"
                  scope="row"
                >
                  {data.comparisonText}
                </th>
                <td className="p-5">{getComparisonIcon(data.cloudMedical)}</td>
                <td className="p-5">{getComparisonIcon(data.otherDoctors)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Comparison;
