import React from "react";
import { Formik } from "formik";
// import ReactRotatingText from "react-rotating-text";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import Ontario from "../../images/svg/ontario.svg";
import { Link } from "gatsby";

interface LandingHeroProps {}

const LandingHero: React.FC<LandingHeroProps> = props => {
  // const content = [
  //   "headaches. ",
  //   "allergies. ",
  //   "COVID-19. ",
  //   "sleep issues. ",
  //   "prescription refills. ",
  //   "back pain. ",
  //   "a rash. ",
  //   "babies. ",
  //   "sore throats. ",
  // ];
  return (
    <div className="px-4 sm:px-6 pt-28 pb-28 lg:pt-44 bg-blue-50">
      <div className="mx-auto max-w-7xl">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="block text-4xl font-extrabold tracking-tight sm:text-6xl xl:text-7xl">
                <span className="block text-gray-900">
                  Exceptional care that goes where{" "}
                  <span className="text-lightblue-500">you</span> go
                </span>
              </span>
            </h1>
            <p className="mt-3 text-lg text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Same-day primary care for acute and chronic health issues.
            </p>

            <form action="#" className="mt-8 sm:max-w-lg sm:w-full sm:flex">
              <div className="min-w-0 flex-1">
                <label htmlFor="hero_email" className="sr-only">
                  Email address
                </label>
                <input
                  id="hero_email"
                  type="email"
                  className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-lightblue-500 focus:ring-lightblue-500"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  className="block w-full rounded-md border border-transparent px-5 py-3 bg-lightblue-500 text-base font-medium text-white shadow hover:bg-lightblue-600 focus:outline-none focus:ring-2 focus:ring-lightblue-500 focus:ring-offset-2 sm:px-10"
                >
                  Get started
                </button>
              </div>
            </form>

            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 flex items-center">
              <Ontario className="h-5 text-gray-500" />
              <p className="text-lg text-gray-500 font-normal mx-5">
                Covered by OHIP
              </p>
            </div>
          </div>

          <div className="sm:max-w-lg sm:mx-auto lg:m-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="overflow-auto flex justify-center items-center object-cover ">
              <StaticImage
                src="../../images/home/blob-hero.png"
                alt="Person TODO"
                placeholder="tracedSVG"
                layout="constrained"
                // width={623}              
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHero;
