import React from "react";
import { PageProps } from "gatsby";

// Components
import SEO from "../components/common/SEO";
import Nav from "../components/common/Nav";
import LandingHero from "../components/home/LandingHero";
import Services from "../components/home/Services";
import Cta from "../components/home/Cta";
import Comparison from "../components/home/Comparison";
import Faq from "../components/home/Faq";
import Footer from "../components/common/Footer";
import HowItWorks from "../components/home/HowItWorks";
import Reviews from "../components/home/Reviews";

const IndexPage: React.FC<PageProps> = props => {
  return (
    <div>
      <SEO title="Exceptional Primary Care"/>
      <Nav />
      <main>
        <LandingHero />
        <HowItWorks />
        <Services />
        <Comparison />
        <Faq />
        <Reviews />
        <Cta />
      </main>
      <Footer />
    </div>
  );
};

export default IndexPage;
